<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
<!--         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat><h3 class="white&#45;&#45;text">Công cụ</h3></v-toolbar>
              <v-card-title>
                <v-row>
                 <v-col
                        cols="6"
                        sm="3"
                    >
                    <v-text-field 
                      label="Tìm kiếm"
                      hint="Tìm kiếm thông tin kiện hàng"
                      persistent-hint
                      outlined
                      persistent-placeholder
                    />
                 </v-col>
                  <v-col
                        cols="6"
                        sm="3"
                    >
                    <v-select 
                      :items="filter"
                      label="Tìm theo"
                      item-value="code"
                      item-text="text"
                      outlined
                    />
                 </v-col>
                 <v-col
                    sm6
                    xs3
                    md3
                    lg3
                  >
                  <v-btn-toggle>
                    <v-btn color="#0f2e8c" class="white&#45;&#45;text" @click=TimKiem>Tìm kiếm  <v-icon right>mdi mdi-clipboard-text-search-outline</v-icon></v-btn>
                  </v-btn-toggle>
                 </v-col>
                </v-row>
              </v-card-title>              
            </v-card>
            <br />
          </template>
         </v-flex>-->
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách chuyến hàng</h3>
                <v-spacer />
								<v-btn class="mx-2" color="blue" dark @click="LayDuLieu()">Tải lại</v-btn>
                <v-btn class="mx-2" color="#00C853" dark @click="boxAdd = true">Thêm</v-btn>
              </v-toolbar>
              <v-card-title>
                <v-row>
									<v-col
										cols="12"
									>
										<v-btn
											large
											class="mx-2"
											v-if="TypeMode === 'air' || TypeMode === 'ship'"
											@click="doUpdateTypeMode('all')"
										>
											Tất cả
										</v-btn>
										<v-btn
											color="blue"
											large
											class="mx-2"
											v-if="TypeMode === 'air' || TypeMode === 'all'"
											dark
											@click="doUpdateTypeMode('ship')"
										>
											Sea
											<v-icon>
												mdi-ferry
											</v-icon>
										</v-btn>
										<v-btn
											color="green"
											large
											class="mx-2"
											v-if="TypeMode === 'ship' || TypeMode === 'all'"
											dark
											@click="doUpdateTypeMode('air')"
										>
											Air
											<v-icon>
												mdi-airplane
											</v-icon>
										</v-btn>
<!--										<v-select
											v-model="TypeMode"
											:items="shipmentSearch"
											label="Loại chuyến hàng"
											item-value="value"
											item-text="text"
											@change="doUpdateTypeMode"
										/>-->
									</v-col>
									<v-col
										cols="auto"
									>
										<v-select
											v-model="Filter.Status"
											:items="StatusSearch"
											label="Trạng thái"
											item-value="value"
											item-text="text"
										/>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-select
											v-model="Filter.ShipmentStatus"
											:items="StatusShipmentSearch"
											label="Trạng thái v/chuyển"
											item-value="value"
											item-text="text"
										/>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-text-field
											v-model="FilterPackageName"
											class="mx-2"
											label="Tìm kiếm mã kiện hàng nhanh"
										/>
										<v-btn
											color="blue"
											class="mx-2"
											dark
											small
											:disabled="loaderPackage"
											@click="doSearchPackageName()"
										>
											Tìm mã kiện hàng
										</v-btn>
									</v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
											@change="doSearch()"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :loading="loading"
                :headers="Headers"
                :items="DanhSachMoi"
                :search="search"
								:options.sync="Filter"
								:server-items-length="totalItems"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
              >
                <template v-slot:[`item.HinhAnh`]="{ item }">
                  <template v-if="!SaleImages(item)">
                    <span class="red--text">Chưa có hình ảnh</span>
                  </template>
                  <v-img
                    :src="SaleImages(item)"
                    :lazy-src="SaleImages(item)"
                    max-width="150px"
                    max-height="150px"
                    v-else
                    @click="doViewImage(item)"
                  />
<!--                  <v-btn-->
<!--                    dark-->
<!--                    class="ma-1"-->
<!--                    color="blue"-->
<!--                    x-small-->
<!--                    @click="doToggleUploadImage(item)"-->
<!--                    :disabled="UploadSaleReportImageState === item._id || SaleLocked(item)"-->
<!--                    :loading="UploadSaleReportImageState === item._id"-->
<!--                  >-->
<!--                    Tải hình ảnh-->
<!--                  </v-btn>-->
                </template>
								<template v-slot:[`item.DocumentUploaded`]="{ item }">
									<template v-if="item['DocumentUploaded']">
										<v-icon color="green">mdi-check-circle</v-icon>
										<br />
										<template v-if="loginInfo._perm === 'admin'">
											<p>
												<v-btn
													color="blue"
													small
													dark
													@click="doLoadDocument(item._id)"
												>Xem tài liệu</v-btn>
											</p>
											<p>
												<v-btn
													color="orange"
													small
													dark
													@click="doRemoveDocument(item._id)"
												>Xóa tài liệu</v-btn>
											</p>
											<br />
										</template>
									</template>
									<template v-else>
										<v-icon color="red">mdi-cancel</v-icon>
									</template>
								</template>
                <template v-slot:[`item.Code`]="{ item }">
                    <template v-if="item.Status !== 'khoa'">
                      <v-btn :to="Link(item._id)" text color="primary">
                        {{ item.Code }}
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn :to="Link(item._id)" text color="orange">{{ item.Code }}</v-btn>
                    </template>
                </template>
                <template v-slot:[`item.ShipmentStatus`]="{ item }">
                  <v-btn text :color="statusShipment[item.ShipmentStatus].color" @click="doOpenChangeShipmentStatus(item)">{{ statusShipment[item.ShipmentStatus].text }}</v-btn>
                </template>
                <template v-slot:[`item.Status`]="{ item }">
                    <v-btn text :color="status[item.Status].color">{{ status[item.Status].text }}</v-btn>
                </template>
								<template v-slot:[`item.CustomPriceFormated`]="{ item }">
									<v-btn text color="green" @click="doChangeCustomPrice(item)">{{ item.CustomPriceFormated }}</v-btn>
								</template>
                <template v-slot:[`item.createAt`]="{ item }">
                    {{ moment(item.createAt).tz("Asia/Bangkok").format("DD-MM-YYYY HH:mm") }}
                </template>
                <template v-slot:[`item._id`]="{ item }">
                    <Manage :item="item" :controls="CustomControls(item)" />
                </template>
                <template v-slot:[`item.Note`]="{ item }">
                    <v-btn
                      @click="showBoxNote(item)"
                      color="blue"
                      dark
                    >
                      Ghi chú
                    </v-btn>
                </template>
              </v-data-table>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                />
              </v-overlay>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <Add :enable.sync="boxAdd" :submitForm="Them"/>
    <LightBox
        ref="lightbox"
        :media="Media"
        :show-light-box="false"
    />
    <v-file-input
        ref="uploader"
        v-model="SaleImageData"
        accept="image/*"
        @change="doUploadImage"
        class="hide"
    />
    <v-dialog
			max-width="600px"
			persistent
			v-model="boxDocument"
		>
			<v-card>
				<v-card-title>Tải lên tài liệu</v-card-title>
				<v-card-text>
					<v-file-input
						v-model="DocumentFile"
						show-size
						truncate-length="15"
						label="Tải lên"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						dark
						@click="boxDocument = false"
					>Close</v-btn>
					<v-btn dark color="green" :disabled="UploadState || DocumentFile === null" :loading="UploadState" @click="doUpload">Tải lên</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="UploadState"/>
		</v-dialog>
    <v-dialog
      max-width="800px"
      persistent
      v-model="boxNote"
    >
      <v-card>
        <v-card-title>Ghi chú</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="GhiChu"
            label="Nội dung"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="boxNote=false"
            dark
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            @click="doSaveNote"
            color="green"
            dark
          >
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
      <Loading :status="loading" />
    </v-dialog>
    <v-dialog
      max-width="800px"
      persistent
      v-model="boxImportTracking"
    >
      <v-card>
        <v-card-title>Import Tracking</v-card-title>
        <v-card-text>
          <ImportTracking :submit="doImportTracking" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            @click="boxImportTracking = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
      <Loading :status="loaderImport" />
    </v-dialog>
    <v-dialog
        max-width="800px"
        persistent
        v-model="boxChangeShipmentStatus"
    >
      <v-card>
        <v-card-title>Cập nhật trạng thái chuyến hàng - {{ShipmentStatusData.Code}}</v-card-title>
        <v-card-text>
          <v-select
              v-model="ShipmentStatusData.ShipmentStatus"
              :value="ShipmentStatusData.ShipmentStatus"
              :items="statusShipmentList"
              item-value="value"
              item-text="text"
              @change="doChangeItemShipmentStatus"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
              dark
              @click="boxChangeShipmentStatus = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
      <Loading :status="loaderShipmentStatus" />
    </v-dialog>
		<v-dialog
			max-width="800px"
			persistent
			v-model="boxChangeCustomPriceData"
		>
			<v-card>
				<v-card-title>Thay đổi đơn giá chuyến hàng - {{CustomPriceData.Code}}</v-card-title>
				<v-card-text>
					<p>
						<v-currency-field
							v-model="CustomPriceData.CustomPrice"
							label="Đơn giá"
						/>
					</p>
					<p>
						<v-checkbox
							v-model="CustomPriceData.DongGia"
							label="Đồng giá ?"
						/>
					</p>
				</v-card-text>
				<v-card-actions>
					<v-btn
						dark
						@click="boxChangeCustomPriceData = false"
					>Close</v-btn>

					<v-btn
						dark
						color="green"
						@click="doProcessChangeCustomPrice"
					>Lưu</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="loaderCustomPrice" />
		</v-dialog>
    <LightBox
        ref="lightbox2"
        :media="Media"
        :show-light-box="false"
    />
  </div>
</template>

<script>
import Query from '@/plugins/query/global'
import { Request } from '@/plugins/helper/fetch'
import {shipment,shipmentStatus} from '@/plugins/helper/status'
import { Status } from '@/plugins/helper/dstatus'
import {mapGetters } from 'vuex'
import Add from '@/components/shipment/add.vue'
import Manage from '@/components/base/thaotac.vue'
import Loading from '../components/base/Loading.vue'
import ImportTracking from '../components/package/import.vue'
import {DoiTien} from '@/plugins/helper/string.js'
import {LoadDocumentShipment} from "../plugins/query/global";
import {serialize} from '@/plugins/helper/object';
import Global from "@/global";
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
export default {
  name: 'shipments',
  components: {
    Add,
    Manage,
    Loading,
    ImportTracking,
    LightBox,
  },
  data () {
    return {
      Media:[],
      SaleImageData: null,
			FilterPackageName: null,
			totalItems:0,
			StatusMode: null,
			StatusShipmentMode: null,
			Upload2Shipment: null,
			UploadProgress: 0,
			DocumentFile: null,
			HeaderUpdated: false,
      notice: new Status(this.$swal),
      status: {
        ...shipment
      },
      statusShipment:{
        ...shipmentStatus,
      },
			StatusSearch:[{value:null,text:'Tất cả'},{value:'dang_xu_ly',text:'Đang xử lý'}, {value:'dang_kiem_tra',text:'Đang kiểm tra'}, {value:'khoa',text:'Đã khóa'}],
			shipmentSearch:[{value:'all',text:'Tất cả'},{value:'ship',text:'Sea'},{value:'air',text:'Air'}],
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      search: '',
      req: new Request('shipment'),
      headers: [
            { text: 'Mã chuyến', value: 'Code', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Số Hawb', value: 'hawbCount', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
						{ text: 'Weight', value: 'Weight', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
						{ text: 'Volumn', value: 'Volumn', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Trạng thái', value: 'Status', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Trạng thái V/chuyển', value: 'ShipmentStatus', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Ghi chú', value: 'Note', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'T.gian tạo', value: 'createAt', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
						{ text: 'Tài liệu', value: 'DocumentUploaded', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Thao tác', value: '_id', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
      ],
      items: [],
      boxAdd: false,
      boxNote: false,
      boxImportTracking: false,
      boxChangeShipmentStatus: false,
			boxChangeCustomPriceData: false,
			boxDocument: false,
      GhiChu: '',
      GhiChuID: '',
      loaderImport: false,
      loaderShipmentStatus:false,
      Change: {
        ID:'',
        Update:{
          Status:  'dang_xu_ly'
        }
      },
			Filter:{
				Status: null,
				ShipmentStatus: null,
				Type:'all',
				Search:null,
			},
      ShipmentStatusData:{},
      Import2Shipment: undefined,
			CustomPriceData: {},
			loaderCustomPrice: false,
			UploadState: false,
			timeOut: null,
			loaderPackage: false,
      UploadShipmentID: null,
      UploadSaleReportImageState: null,
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    statics(){
      return `${Global.staticTOP}/bao-cao-shipment`;
    },
		TypeMode(){
			return this.Filter.Type;
		},
    statusShipmentList(){
      return Object.keys(this.statusShipment).map(key=>{
        return {
          'value':key,
          'text':this.statusShipment[key].text,
        }
      })
    },
		StatusShipmentSearch(){
			const statusShipmentList = this.statusShipmentList;
			return [{value:null,text:'Tất cả'}].concat(statusShipmentList);
		},
    DanhSachMoi: function(){
			return this.items.map(
				(items, index) => ({
					...items,
					index: index + 1,
					CustomPriceFormated: DoiTien(items.CustomPrice)
				}));
		},
		Headers:function(){
			let headers = this.headers;
			if(!this.HeaderUpdated) {
				if (this.loginInfo._perm === 'admin') {
					const len = headers.length;
					this.HeaderUpdated = true;
					headers.splice(len - 1, 0, {
						text: 'Cước',
						value: 'CustomPriceFormated',
						align: 'center',
						width: "auto",
						class: "grey lighten-2 black--text"
					},)
				}
        if(this.loginInfo._perm === 'sale' && !this.loginInfo.TopSale){
          this.HeaderUpdated = true;
          headers.splice(7, 0, {
            text: 'Hình ảnh',
            value: 'HinhAnh',
            align: 'center',
            width: "125px",
            class: "grey lighten-2 black--text"
          });
        }
			}
			return headers;
		}
  },
	watch: {
		Filter: {
			handler () {
				this.LayDuLieu();
			},
			deep: true,
		},
	},
	methods: {
    ...Query,
    doViewImage(item){
      const image = this.SaleImages(item);
      if(image) {
        this.Media = [{
          type: 'image',
          src: image,
          thumb: image,
        }];
        this.$refs.lightbox2.showImage(0);
      }
    },
    doToggleUploadImage(item){
      this.UploadShipmentID = item._id;
      this.SaleImageData = null;
      this.$refs.uploader.$refs.input.click();
    },
    doUploadImage(){
      if (this.SaleImageData) {
        this.UploadSaleReportImageState = this.UploadShipmentID;
        return this.req.UploadFile(`/image-report/${this.UploadShipmentID}`, this.SaleImageData).then(resp=>resp.data).then(json=>{
          const {success, error} = json;
          if(success){
            this.SaleImageData = null;
            this.notice.success("Đã tải lên hình ảnh thành công !!!");
            return this.LayDuLieu();
          }
          if(error){
            this.notice.error(error);
          }
        }).finally(()=>this.UploadSaleReportImageState = null);
      } else {
        this.notice.error('Vui lòng chọn hình ảnh để tải lên !!!');
      }
    },
    SaleLocked(item){
      const Reports = (item['Reports'] && Array.isArray(item['Reports'])) ? item['Reports']:[];
      const SaleReport = Reports.filter(f=>f.Sale.toString() === this.loginInfo.id.toString())[0];
      if(SaleReport && SaleReport['Confirmed'] === true) return true;
      return false;
    },
    SaleImages(item){
      const Reports = (item['Reports'] && Array.isArray(item['Reports'])) ? item['Reports']:[];
      const SaleReport = Reports.filter(f=>f.Sale.toString() === this.loginInfo.id.toString())[0];
      if(SaleReport && SaleReport['Image']) return `${this.statics}/${SaleReport['Image']}`
      return null;
    },
		doSearchPackageName(){
			const {FilterPackageName} = this;
			this.loaderPackage = true;
			this.req.get(`find-package-name?packageName=${FilterPackageName}`).then(resp=> {
				return resp.json()
			}).then(json=>{
				if(json.data) {
					this.$router.push(`/shipment/${json.data}?Package=${FilterPackageName}`);
				}
				if(json.error){
					let msg = json.error || 'Có lỗi trong quá trình tìm kiếm !!!';
					this.notice.error(msg);
				}
			}).catch(()=> {
				this.notice.error('Có lỗi trong quá trình tìm kiếm !!!');
			}).finally(()=>{
				this.loaderPackage = false;
			})
		},
		doSearch(){
			clearTimeout(this.timeOut);
			const app = this;
			this.timeOut = setTimeout(() => {
				app.Filter.Search = app.search;
			}, 1000);
		},
		doUpdateTypeMode(Type){
			//const {TypeMode} = this;
			this.Filter.Type = Type;
			localStorage.setItem("ShipmentTypeMode", Type);
		},
		async doRemoveDocument(id){
			const confirm = await this.notice.confirm('Bạn có xác nhận xóa ? Hành động không thể phục hồi !!!');
			if(confirm){
				this.req.delete('doc/'+id).then(async resp=>{
					const json = await resp.json()
					if(json.success === true){
						this.notice.success("Đã xóa tài liệu !!!");
						this.LayDuLieu()
					}else{
						const msg = json.error || "Không thể xóa tài liệu !!!"
						this.notice.error(msg);
					}
				})
					.catch(err=>{
						this.notice.error(err);
					})
			}
		},
		doLoadDocument(id){
			this.LoadDocumentShipment(id).then(resp=>{
				const {data} = resp;
				if(data){
					window.open('https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/'+data);
				}
			})
		},
		doProcessChangeCustomPrice(){
			const {id, CustomPrice, DongGia} = this.CustomPriceData;
			this.loaderCustomPrice = true;
			this.req.patch(`cp/${id}`,{CustomPrice, DongGia}).then(resp=> {
				return resp.json()
			}).then(json=>{
				if(json.success === true) {
					this.boxChangeCustomPriceData = false;
					this.notice.success('Đã cập nhật thông tin !!!');
					this.LayDuLieu();
				}else{
					this.notice.error('Có lỗi trong quá trình cập nhật !!!');
				}
			}).catch(()=> {
				this.notice.error('Có lỗi trong quá trình cập nhật !!!');
			}).finally(()=>{
				this.loaderCustomPrice = false;
			})
		},
		doChangeCustomPrice(item){
			this.CustomPriceData = Object.assign({},item);
			this.boxChangeCustomPriceData = true;
		},
    doChangeItemShipmentStatus(newStatus){
      this.loaderShipmentStatus = true;
      const {_id} = this.ShipmentStatusData;
      this.req.update(_id,{ShipmentStatus:newStatus}).then(resp=>{
        return resp.json()
      })
          .then(json=>{
            if(json.success === true)
            {
              this.boxChangeShipmentStatus = false;
              this.notice.success('Đã cập nhật trạng thái !!!');
              this.LayDuLieu();
            }else{
              const e = json.error || "Không thể cập nhật trạng thái !!!"
              this.notice.error(e)
            }
          }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loaderShipmentStatus = false;
      })
    },
    doOpenChangeShipmentStatus(item){
      if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return undefined;
      this.ShipmentStatusData = Object.assign({},item);
      this.boxChangeShipmentStatus = true;
    },
    doImportTracking(data,providers){
      this.loaderImport = true;
      const request = {data,providers};
      this.req.importTracking(this.Import2Shipment,request).then(()=>{
        this.boxImportTracking = false;
        this.notice.success('Đã cập nhật tracking !!!');
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loaderImport = false
      })
    },
    ImportTracking(item){
      this.Import2Shipment = item._id;
      this.boxImportTracking = true;
    },
    doSaveNote(){
      this.loading = true
      this.req.note(this.GhiChuID,{Note:this.GhiChu}).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        if(json.success === true)
        {
          this.boxNote = false;
          this.notice.success('Đã cập nhật ghi chú !!!');
        }else{
          const e = json.error || "Không thể cập nhật ghi chú !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loading = false
      })
    },
    showBoxNote(item){
      this.GhiChu = item.Note;
      this.GhiChuID = item._id;
      this.boxNote = true;
    },
    CustomControls(item){
      let Controls = [{color:'red',text:'Xóa',do:this.Xoa}]
      if(item.Count > 0)
      {
        Controls.splice(0,1)
      }
      if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager' || this.loginInfo._perm === 'accounting'){
        Controls.splice(1,0,{color:'violet',text:'Báo cáo',do:this.Report})
      }
      if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager' || this.loginInfo._perm === 'warehousevn'){
        Controls.splice(1,0,{color:'indigo',text:'Import Tracking',do:this.ImportTracking})
      }
      if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager'){
        if(item.Status !=='dang_kiem_tra') Controls.splice(1,0,{color:'blue',text:'Chế độ kiểm tra',do:this.CheckMode})
        if(item.Status !=='dang_xu_ly') Controls.splice(1,0,{color:'green',text:'Chế độ xử lí',do:this.OpenMode})
        if(item.Status !=='khoa') Controls.splice(1,0,{color:'orange',text:'Chế độ khóa',do:this.LockMode})
      }else if(this.loginInfo._perm === 'sale'){
        Controls = [{color:'green',text:'Báo cáo',do:this.SaleReport}]
      }
      if(this.loginInfo._perm !== 'sale' && this.loginInfo._perm !== 'warehouse'){
        Controls.splice(1,0,{color:'yellow',text:'Invoice',do:this.GetInvoice})
        Controls.splice(1,0,{color:'green',text:'Excel',do:this.ExportExcel})
      }
			Controls.push({color:'green',text:'Tài liệu',do:this.UploadDocument});
      return Controls
    },
		UploadDocument(item){
			if(item['DocumentUploaded']){
				this.notice.error('Chuyến hàng này đã có tài liệu. Không thể tải lên thêm !');
				return;
			}
			this.DocumentFile = null;
			this.boxDocument = true;
			this.UploadProgress = 0;
			this.Upload2Shipment = item._id;
		},
		doUpload() {
			this.UploadState = true;
			this.req.Upload(this.Upload2Shipment, this.DocumentFile, (event) => {
				this.UploadProgress = Math.round((100 * event.loaded) / event.total);
			},).then(axios => {
				const {success, error} = axios.data
				if (success) {
					this.notice.success("Đã tải tài liệu thành công !!!");
					this.boxDocument = false;
					this.LayDuLieu();
				} else {
					const e = error || "Không thể tải lên tài liệu !!!"
					this.notice.error(e)
				}
			})
				.catch(e => {
					this.notice.error(e)
				})
				.finally(() => {
					this.UploadState = false;
				})
		},
    ExportExcel(item){
      this.loading = true
      this.req.fetch('excel/'+item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        if(json.success === true && json.data)
        {
          const STORAGE = 'https://statics.tomexpress.jp/tomexpress/'
          let a = document.createElement("a");
          a.href = STORAGE+json.data
          a.click()
        }else{
          const e = json.error || "Không thể tạo báo cáo !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loading = false
      })
    },
    GetInvoice(item){
      this.loading = true
      this.req.fetch('invoice/'+item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        if(json.success === true && json.data)
        {
          const STORAGE = 'https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/'
          let a = document.createElement("a");
          a.href = STORAGE+json.data
          a.click()
        }else{
          const e = json.error || "Không thể tạo báo cáo !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loading = false
      })
    },
    SaleReport(item){
      this.$router.push('/Sale/report/'+item._id)
    },
    Report(item){
      this.loading = true
      this.req.fetch('report/'+item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        if(json.success === true && json.data._id)
        {
          this.$router.push(`/report/${json.data._id}`)
        }else{
          const e = json.error || "Không thể tạo báo cáo !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loading = false
      })
    },
    LockMode(item){
      this.Change = {
        ID:item._id,
        Update:{
          Status:'khoa'
        }
      }
      this.changeMode()
    },
    CheckMode(item){
      this.Change = {
        ID:item._id,
        Update:{
          Status:'dang_kiem_tra'
        }
      }
      this.changeMode()
    },
    OpenMode(item){
      this.Change = {
        ID:item._id,
        Update:{
          Status:'dang_xu_ly'
        }
      }
      this.changeMode()
    },
    changeMode(){
      this.loading = true
      const p = {...this.Change}
      this.req.update(p.ID,p.Update).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể cập nhật cho Shipment"
          this.notice.error(msg)
        }
      })
      .catch(err=>{
        this.notice.error(err)
      })
      .finally(()=>{
        this.loading = false
      })
    },
		//Phần lấy dữ liệu
    LayDuLieu() {
      this.loading = true;
			let query = serialize(this.Filter);
      this.ListShipments('?'+query).then(resp=>{
        this.items = resp.data;
				this.totalItems = resp.total;
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
      // this.loading = true
      // this.req.fetch().then(async resp=>{
      //   const json = await resp.json()
      //   this.items = json.data
      // })
      // .catch(err=>{
      //   this.notice.error(err)
      // })
      // .finally(()=>{
      //   this.loading = false
      // })
    },
    Link(Code){
      return `/shipment/${Code}`
    },
    TimKiem() {

    },
    Them(data) {
      this.req.create(data).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.boxAdd = false
          this.notice.success("Đã thêm mới dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể thêm mới dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    }
  },
  mounted(){
		this.Filter.Type = localStorage.getItem("ShipmentTypeMode") ? localStorage.getItem("ShipmentTypeMode"): 'all';
	}
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>