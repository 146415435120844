<template>
  <div>
    <h3>Import XLSX</h3>
    <input type="file" @change="onChange" />
    <xlsx-read :file="file">
      <xlsx-json>
        <template #default="{collection}">
          <div>
            Số lượng: {{ parseJson(collection) }}
          </div>
        </template>
      </xlsx-json>
    </xlsx-read>
    <br>
    <v-responsive
      max-height="200px"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="data"
        loading-text="Đang tải dữ liệu..."
        :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
        :items-per-page="50"
      />
    </v-responsive>
    <br />
      <v-select v-model="providers"
        :items="shipmentproviders"
        item-value="value"
        item-text="text"
        label="Đơn vị vận chuyển"
      />
    <br />
    <v-btn
      color="green"
      dark
      :disabled="data.length === 0"
      @click="submit(data,providers)"
    >
      Import
    </v-btn>
  </div>
</template>

<script>
import { XlsxRead, XlsxJson } from "vue-xlsx"

export default {
  components: {
    XlsxRead,
    XlsxJson
  },
  data() {
    return {
      file: null,
      data:[],
      providers:'vnpost',
      shipmentproviders:[
        {value:"vnpost",text:"VNPOST"},
        {value:"jtexpress",text:"J&T EXPRESS"},
				//{value:"vtpost",text:"VIETTEL POST - SG"},
        {value:"vtposthn",text:"VIETTEL POST - HN"},
        {value:"vtpostsg",text:"VIETTEL POST - SG"},
        {value:"nhattin",text:"NHẤT TÍN"},
      ],
      headers: [
          { text: 'Mã Kiện Hàng', align: 'center', sortable: true,value: 'ma_kien_hang',width:"50%",class:"grey lighten-2 black--text" },
          { text: 'Tracking', align: 'center', sortable: false,value: 'tracking',width:"50%",class:"grey lighten-2 black--text" },
      ],
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    parseJson(data){
      if(data !== null){
        this.data = data.map(item=>{
          if(item.TRACKING){
            return {
              ma_kien_hang: item["MÃ KIỆN HÀNG"],
              tracking: item.TRACKING
            }
          }
        }).filter(v=>!!v);
        return this.data.length;
      }else{
        return 0;
      }
    }
  },
  props:["submit"],
};
</script>