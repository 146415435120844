<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm chuyến hàng</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items  class="btn_hawwb">
            <v-btn 
              dark 
              text
              @click="submitForm(submitData)"
            >
              Thêm
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          two-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-select 
                  v-model="submitData.Type"
                  :items="Type"
                  label="Loại chuyến hàng"
                  item-value="code"
                  item-text="text"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-text-field 
                  v-model="submitData.Name"
                  label="Mã chuyến hàng"
                  :rules="[required('Mã chuyến')]"
                  :prefix="getPrefix"
                  @change="UpdateNameAuto"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-text-field 
                  v-model="submitData.NameAuto"
                  label="Mã CH tự động"
                  :rules="[required('Mã CH tự động')]"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-currency-field
                  v-model="submitData.CustomPrice"
                  label="Giá chuyến hàng"
                  :rules="[required('Giá chuyến hàng')]"
                />
                <br>
                <v-switch
                  v-model="submitData.DongGia"
                  :label="submitData.DongGia ? 'Đồng giá':'Không đồng giá'"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import validations from '@/plugins/helper/validations'
import {genShipmentName} from '@/plugins/helper/string'
export default {
  data () {
    return {
      valid:false,
      dialog: false,
      submitData:{
        Type:'air',
        Name: '',
        NameAuto: '',
        CustomPrice: 0,
        DongGia: false,
      },
      Type: [{code:'air',text:'MÁY BAY'},{code:'ship',text:'TÀU BIỂN'}]
    }
  },
  computed:{
    getPrefix(){
      return genShipmentName(this.submitData.Type)
    }
  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    }
  },
  methods: {
    ...validations,
    UpdateNameAuto(){
      const autoName = genShipmentName('')+this.submitData.Name
      this.submitData.NameAuto = autoName.replace(/[0-]/g, '');
    },
  },
  props: ["enable","submitForm"]
}
</script>

<style>

</style>